@font-face {
  font-family: 'Noteworthy Light';
  font-style: normal;
  font-weight: normal;
  src: local('Noteworthy Light'), url('./../Fonts/Noteworthy-Lt.woff') format('woff');
  }

h1 {
  font-family: 'Noteworthy Light';
}


h2 {
  font-family: 'Noteworthy Light';
}

.item {
  padding: 10px;
}

.main{
  background-color: #282c34;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.main h1{
  color: #fff;
  font-size: 40px;
  text-align: center;
}

.details { 
  display: flex;
    align-items: center;
    justify-content: center;
}

.main h2{
  color: #fff;
  font-size: 20px;
  text-align: center;
}

.main img{
  width: 100%;
  max-width: 350px;
}

.container{
  padding: 20px 10px 20px 10px;
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
} 

.content{
  padding-top: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.item{
  padding: 10px;
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.header nav{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.header a{
  color: #fff;
  text-decoration: none;
  font-size: 25px;
  margin-left: 20px;
}
.sub_header{
  color: #fff;
  text-decoration: none;
  font-size: 25px;
  margin-left: 20px;
  text-align: center;
  direction: rtl;
}

.header a:first-of-type{
  margin-left: 0;
}

@media screen and (max-width: 575px){
  .header{
    justify-content: center;
  }

  .header nav{
    padding-top: 20px;
  }
}