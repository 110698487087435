.share_button {
    width: 94.7%;
    height: 30px;
    background-color: #2727279a;
    color: white;
    bottom: 8px;
    position: absolute;
}

.item {
    position: relative;
}